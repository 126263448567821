import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { graphql, navigate } from "gatsby";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  PROFILE_STEPS,
  ProfessionalDto,
  Language,
  AddressDto,
} from "../../../../dto/interfaces";
import { Button, Select } from "../../../../components/base";
import { useAppContext } from "../../../../context/AppProvider";
import { useMutation } from "react-query";
import axios, { AxiosError } from "axios";
import { URL_API } from "../../../../utils/constants";
import handleError from "../../../../utils/handleError";
import { PracticeArea, Specialization } from "../../../../dto/Lawyer";
import { Progress } from "flowbite-react";
import { fetchUser } from "../../../../components/views/AdditionalInformation/EducationForm";

export const LANGUAGES = [
  {
    id: Language.ENGLISH,
    key: "english",
    en: "English",
    es: "Inglés",
  },
  {
    id: Language.SPANISH,
    key: "spanish",
    en: "Spanish",
    es: "Español",
  },
  {
    id: Language.GERMAN,
    key: "german",
    en: "German",
    es: "Alemán",
  },
  {
    id: Language.PORTUGUESE,
    key: "portuguese",
    en: "Portuguese",
    es: "Portugués",
  },
  {
    id: Language.FRENCH,
    key: "french",
    en: "French",
    es: "Francés",
  },
  {
    id: Language.ITALIAN,
    key: "italian",
    en: "Italian",
    es: "Italiano",
  },
];

const ProfessionalForm = () => {
  const { t } = useTranslation();

  const { user, practiceAreas, specializations, accessToken, setContextValue } =
    useAppContext();

  const isEdit = !!user?.userProfile?.professional?.specializations;
  const ProfessionalSchema = yup.object().shape({
    specializations: yup
      .array()
      .of(yup.number())
      .required(t("Specializations are required")),
    practiceAreas: yup
      .array()
      .of(yup.number())
      .required(t("Practice Areas are required")),
    languages: yup
      .array()
      .of(yup.string())
      .required(t("Languages are required")),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({ resolver: yupResolver(ProfessionalSchema) });

  const [professionalError, setProfessionalError] = useState<string | null>(
    null
  );

  const mutation = useMutation(
    (data: ProfessionalDto) =>
      axios.post(
        `${URL_API}/user-profile/${user?.userProfile?.id}/professional`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      ),
    {
      onError: (e: AxiosError) => {
        if (e.response?.status === 401) {
          navigate("/auth/login");
        }
        setProfessionalError(handleError(e));
      },
      onSuccess: () => {
        fetchUser(user, accessToken, setContextValue);

        isEdit
          ? navigate("/auth/user-profile/")
          : navigate(PROFILE_STEPS[3].path);
      },
    }
  );

  const onSubmit = (data: ProfessionalDto) => {
    console.log(
      "DEBUG ~ file: ProfessionalForm.tsx:134 ~ onSubmit ~ data",
      data
    );
    mutation.mutate(data);
  };

  useEffect(() => {
    if (user?.userProfile?.professional?.specializations) {
      setValue(
        "specializations",
        (user?.userProfile?.professional?.specializations as any).map(
          (s: Specialization) => s.id
        )
      );
    }
    if (user?.userProfile?.professional?.practiceAreas) {
      setValue(
        "practiceAreas",
        (user?.userProfile?.professional?.practiceAreas as any).map(
          (s: PracticeArea) => s.id
        ) as any
      );
    }
    if (user?.userProfile?.professional?.languages) {
      setValue("languages", user?.userProfile?.professional?.languages);
    }
  }, [user]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mx-auto w-full">
      <p className="text-gray-500 text-center py-4">
        {!isEdit ? `${t("Step")} 3/6:` : `${t("Update")}`}{" "}
        {t(PROFILE_STEPS[2].name)}
      </p>
      <div className="my-6">
        <Controller
          name="specializations"
          control={control}
          defaultValue={[]}
          render={({ field }) => (
            <Select
              items={specializations}
              value={field.value}
              onChange={field.onChange as any}
              multiple={true}
              placeholder={t("Select a Specialization")}
            />
          )}
        />

        {errors.specializations && (
          <span className="text-red-500">{errors.specializations.message}</span>
        )}
      </div>
      <div className="my-6">
        <Controller
          name="practiceAreas"
          control={control}
          defaultValue={[]}
          render={({ field }) => (
            <Select
              items={practiceAreas}
              value={field.value}
              onChange={field.onChange as any}
              multiple={true}
              placeholder={t("Select a Practice Area")}
            />
          )}
        />

        {errors.practiceAreas && (
          <span className="text-red-500">{errors.practiceAreas.message}</span>
        )}
      </div>
      <div className="mb-6">
        <Controller
          name="languages"
          control={control}
          defaultValue={[]}
          render={({ field }) => (
            <Select
              placeholder={t("Select a Language")}
              items={LANGUAGES}
              value={field.value}
              onChange={field.onChange as any}
              disabled={false}
              multiple={true}
            />
          )}
        />

        {errors.languages && (
          <span className="text-red-500">{errors.languages.message}</span>
        )}
      </div>
      {professionalError && (
        <div className="my-4 flex w-full justify-center">
          <span className="w-full text-center font-medium text-red-500">
            {professionalError}
          </span>
        </div>
      )}
      <Button
        type="submit"
        variant="teal"
        className="h-12"
        disabled={mutation.isLoading}
      >
        {mutation.isLoading ? (
          <Trans>Saving...</Trans>
        ) : isEdit ? (
          t("Save")
        ) : (
          t("Continue")
        )}
      </Button>
    </form>
  );
};

export default ProfessionalForm;
