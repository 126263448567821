import { FaBalanceScale, FaCameraRetro, FaGraduationCap } from "react-icons/fa";
import { FaColonSign } from "react-icons/fa6";
import { HiAcademicCap } from "react-icons/hi";
import { ImLocation, ImProfile } from "react-icons/im";
import { SiAboutdotme } from "react-icons/si";

export interface LoginDto {
  email: string;
  password: string;
}

export enum RoleType {
  BASIC = "BASIC",
  ATTORNEY = "ATTORNEY",
  ADMIN = "ADMIN",
}
export interface RegisterDto {
  email: string;
  password: string;
  rePassword: string;
  fullName: string;
  phoneNumber: string;
  role: RoleType;
}

export interface ForgotPasswordDto {
  email: string;
}

export enum TypeOfConsultation {
  ONLINE = "ONLINE",
  IN_PERSON = "IN_PERSON",
  AT_HOME = "AT_HOME",
}
export interface GeneralProfile {
  firmName: string;
  licenseNumber: string;
  website: string;
  phoneNumber: string;
  typeOfConsultation?: TypeOfConsultation | null; // Puede ser opcional y nulo
  facebook: string;
  instagram: string;
  dni: string;
}
export interface AddressProfile {
  district: string;
  exactAddress: string;
  province: string;
  canton: string;
}

export interface ProfessionalProfile {
  specializations: string[];
  practiceAreas: string[];
  languages: string[];
  isNotary: boolean;
}
export interface Specialization {
  id: number;
  key: string;
  en: string;
  es: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface PracticeArea {
  id: number;
  key: string;
  en: string;
  es: string;
  createdAt: Date;
  updatedAt: Date;
}

export enum Language {
  ENGLISH = "ENGLISH",
  SPANISH = "SPANISH",
  GERMAN = "GERMAN",
  PORTUGUESE = "PORTUGUESE",
  FRENCH = "FRENCH",
  ITALIAN = "ITALIAN",
}

export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
  OTHER = "OTHER",
}

export interface AboutProfile {
  avatar: string;
  gender: string;
  dateOfBirth: Date;
  aboutMe: string;
}
export interface PaymentMethodsProfile {
  hourlyRate: string;
  methods: string[];
}

export interface ProfessionalDto extends ProfessionalProfile {}
export interface UserProfile extends GeneralProfile {}
export interface GeneralDto extends GeneralProfile {}
export interface AddressDto extends AddressProfile {}
export interface AboutDto extends AboutProfile {}
export interface PaymentMethodsDto extends PaymentMethodsProfile {}

export const PROFILE_STEPS = [
  {
    name: "General Profile",
    path: "/auth/user-profile/general/",
    icon: ImProfile,
  },
  {
    name: "Address",
    path: "/auth/user-profile/address/",
    icon: ImLocation,
  },
  {
    name: "Professional Profile",
    path: "/auth/user-profile/professional/",
    icon: FaBalanceScale,
  },
  {
    name: "Payment Method",
    path: "/auth/user-profile/payment-methods/",
    icon: FaColonSign,
  },
  {
    name: "About Me",
    path: "/auth/user-profile/about/",
    icon: FaCameraRetro,
  },
  {
    name: "Additional Information",
    path: "/auth/user-profile/additional-information/",
    icon: FaGraduationCap,
  },
];
export enum UserStatus {
  ACTIVE = "ACTIVE",
  SUSPENDED = "SUSPENDED",
  PENDING_APPROVAL = "PENDING_APPROVAL",
}
