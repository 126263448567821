import React, { InputHTMLAttributes, ForwardRefRenderFunction } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { IconType } from "react-icons";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  iconLeft?: IconType;
  iconErrorDisplay?: boolean;
  register?: UseFormRegisterReturn;
  label?: string;
}

const Input: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  { iconLeft: IconLeft, label, ...props },
  ref
) => {
  return (
    <>
      {props.placeholder && (
        <label htmlFor={props.id} className="pb-4 font-medium text-gray-700">
          {props.placeholder}
        </label>
      )}

      <div className="relative px-0.5">
        {IconLeft && (
          <span
            className={`absolute left-2.5 top-1/2 -translate-y-1/2 items-center text-gray-500`}
          >
            <IconLeft size={20} />
          </span>
        )}

        <input
          className={`h-12 w-full appearance-none rounded-lg border bg-transparent p-1 ${
            IconLeft ? "pl-8" : "pl-2"
          } pr-4 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-teal-600`}
          ref={ref}
          {...props}
        />
      </div>
    </>
  );
};

export default React.forwardRef(Input);
