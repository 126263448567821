import { LANGUAGES } from "../pages/auth/user-profile/professional/ProfessionalForm";

const URL_API = "https://juriside-backend.onrender.com";
// const URL_API = "http://localhost:3000";
export { URL_API };
export const capitalize = (s: string): string => {
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};
export function formatCurrency(
  amount: number,
  withoutSign: boolean = false
): string {
  let [integerPart, decimalPart = ""] = amount.toFixed(2).split(".");

  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return `${integerPart}.${decimalPart} ${withoutSign ? "" : "₡"}/hr`;
}
type LanguageKeys = "en" | "es";

export function convertLanguage(
  language: string,
  currentLanguage: LanguageKeys
): string | undefined {
  const result = LANGUAGES.find((l) => language === l.id);
  return result?.[currentLanguage];
}
