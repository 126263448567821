"use client";
import React, { useEffect, useState } from "react";
import ThTable from "./ThTable";
import TrTable from "./TrTable";

export interface columnInt {
  key: string;
  title: string;
  className?: string;
}

export interface TableProps<T extends { id: string | number }> {
  id?: string;
  data: T[];
  hasDeleteIcon?: boolean;
  onClickTr: (data: T) => void;
}

const Table = <T extends { id: string | number }>({
  id,
  data,
  onClickTr,
  hasDeleteIcon = false,
  ...props
}: TableProps<T>) => {
  const [sortBy, setSortBy] = useState<[key: string, order: "ASC" | "DESC"]>([
    "",
    "ASC",
  ]);
  const [sortedData, setSortedData] = useState<T[]>(data);
  const [columns, setColumns] = useState<columnInt[]>([]);

  useEffect(() => {
    const extractColumns = (): columnInt[] => {
      if (data.length > 0) {
        const firstItem = data[0];
        return Object.keys(firstItem).map((key) => ({
          key,
          title: key,
        }));
      }
      return [];
    };

    const [sortName, sortType] = sortBy;
    const sortedData = [...data].sort((a, b) => {
      const valueA = a[sortName as keyof T];
      const valueB = b[sortName as keyof T];

      if (valueA < valueB) {
        return sortType === "ASC" ? -1 : 1;
      }
      if (valueA > valueB) {
        return sortType === "ASC" ? 1 : -1;
      }
      return 0;
    });

    setColumns(extractColumns());
    setSortedData(sortedData);
  }, [data, sortBy]);

  const customSortBy = (payload: string) => {
    const [sortName, sortType] = sortBy;
    if (sortName === payload) {
      setSortBy([sortName, sortType === "ASC" ? "DESC" : "ASC"]);
    } else {
      setSortBy([payload, "ASC"]);
    }
  };

  return (
    <div className="max-h-[80vh] max-w-[90vw] w-auto overflow-y-auto overflow-x-auto">
      <table
        id={id}
        {...props}
        className="w-full table-fixed max-w-full  border-collapse"
      >
        <caption className="sr-only">Table caption</caption>
        <thead className="bg-gray-50">
          <tr>
            {columns.map(({ key, title, className }) =>
              key === "id" ? (
                <></>
              ) : (
                <ThTable
                  key={key}
                  title={title}
                  sortBy={sortBy}
                  setSortBy={customSortBy}
                  className={className}
                />
              )
            )}
            {hasDeleteIcon && (
              <ThTable title={""} sortBy={sortBy} setSortBy={customSortBy} />
            )}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((rowData) => (
            <TrTable
              key={rowData.id}
              rowData={rowData}
              columns={columns}
              onClickTr={onClickTr}
              hasDeleteIcon={hasDeleteIcon}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
