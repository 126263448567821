import { AxiosError } from "axios";

const handleError = (error: AxiosError) => {
  // Define a type for error.response.data
  interface ErrorResponseData {
    message: string | string[];
  }

  // Use type assertion to tell TypeScript error.response.data is of type ErrorResponseData
  const responseData = error.response?.data as ErrorResponseData;

  if (Array.isArray(responseData?.message)) {
    return responseData.message[0];
  }
  return responseData?.message || error.message;
};

export default handleError;
